const tableTemplate = (elementName: string) =>

    `<search name="${elementName.trim().toLowerCase()}" label="${elementName}" className="empty-search">
<view>
<screen labelWidth="150">
    <framedpanel label="Search Criteria" usage="embedded:N">
        <vbox columns="90% 10%">    
            <field label="Mots Clés" widget="text" labelAlign="float" xpath="query.unifiedCriteria" labelWidth="200" />          
            <button attribute="visible" labelAlign="float" icon="fas fa-search" label="" ratio="0" width="60" postProcessing="search()" />          
            <button postProcessing="mode=expand" labelAlign="float" icon="fas fa-filter" label="" ratio="0" width="60">            
                <dependency attribute="visible" expression="{mode},=,collapse" />          
            </button>    
      
        </vbox>
</framedpanel>
</screen>
<buttonBar></buttonBar>
<objectList targetScreen="default" viewerType="Table">
 
</objectList>
</view>
</search>`;


export { tableTemplate };
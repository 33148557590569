import {   useState ,useRef, useEffect } from 'react'; 
import { Link } from 'react-router-dom';
import { ModulesExplorer } from '../kls-module/modules-explorer';
import { SaveApplicationForm } from '../kls-module/save-application-from';
import ProfileMenu from '../menu/profile-menu';
import NotificationPanel from '../notification/notification-panel';
import { currentUser } from 'kls-commons/service/userService'; 
import { modelNameToLabel } from 'kls-commons/help/tools';
import { GlobalStateModel } from 'kls-commons/types/application-model';
import { Avatar } from 'kls-ui';
import './layout.css';
import { GS } from 'kls-commons/state/global-state';



function Layout(props: {gs:GlobalStateModel, postSignOut: Function, children: any, moduleTitle:string, modelTitle:string}) {
    const gs: GlobalStateModel = props.gs; 
    let [notificationMark, setNotificationMark] = useState(false); 
    let [saveAll, setSaveAll] = useState(false);
    let [showModulesExplorer, setShowModulesExplorer] = useState(false);
    let currentModelNameLS = localStorage.getItem("currentModelName"); 
    let currentModelName = currentModelNameLS || GS.currentModelName || '';
    let currentModuleName = GS.currentModuleName || GS.modules?.[0]?.name || '';   
    
    console.log("currentModelName==", currentModelName, "  currentModuleName "+currentModuleName);
    
    // let moduleTitle = currentModuleName;
    // let modelTitle = modelNameToLabel(currentModelName || '');

    let appMenus = [{label: "Modèles", name: "browse-models", icon:"/icons/folder-elipsis.svg" }, 
        {label: "Workflows", name: "workflows", icon:"/icons/project-diagram.svg" }, 
        {label: "Configuration", name: "configuration", icon:"/icons/sliders-up.svg" }, 
    ] 
    let [showProfile, setShowProfile] = useState(false) as any;
    let [showNotification, setShowNotification] = useState(true) as any;
    
    let toggleModuleExplorer = ()=> {
        setShowModulesExplorer(!showModulesExplorer);
    }
    const dropdownDisconnectRef = useRef<HTMLDivElement>(null);
    const dropdownModuleRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (dropdownDisconnectRef.current && !dropdownDisconnectRef.current.contains(event.target as Node)) {
          setShowProfile(false);
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [dropdownDisconnectRef]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
          if (dropdownModuleRef.current && !dropdownModuleRef.current.contains(event.target as Node)) {
            setShowModulesExplorer(false);
          }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [dropdownModuleRef]);
    
    return (
        <div className="Layout">
            <div className="kls-content kls-content-dashboard-tx ">
                <div className="kls-header kls-header-dashboard-tx">
                    <div className="container-fluid app-rubon">

                        <div className="kls-header-logo">
                            <img className="kls-header-logo" src="/icons/ksw-picto.svg" alt="KSW"></img>
                        </div>
                        <div className="kls-header-entity"> 
                            <div className="kls-header-entity-modelName">{props.modelTitle}</div>
                            <div className="kls-header-entity-moduleName">{props.moduleTitle}</div>
                            {showModulesExplorer && <div ref={dropdownModuleRef} className='dropdown-module-explorer'>
                                <div className='close-btn' onClick={toggleModuleExplorer}>
                                    <img src="/icons/xmark.svg" alt="close" ></img>
                                </div>
                                 <ModulesExplorer gs={props.gs}  onClose={toggleModuleExplorer}></ModulesExplorer></div>}
                  
                        </div>
                        <div className='modules-explorer-anchore' onClick={()=>toggleModuleExplorer()} >
                            <img  src="/icons/elipsis-storke-v.svg" alt="extra"></img>
                        </div>
                    
                        <div className="kls-header-center">

                        {props.moduleTitle && appMenus.map((e, i) => {
                            return <div className="app-menu " key={"st-" + i}  >
                                <Link to={"/edit-module/" +props.moduleTitle+"/"+ e.name} className={ "service-link selected"}>
                                   <img className='app-menu-img' src={e.icon} alt="menu" ></img> <span className="app-menu-label">{e.label} </span>
                                </Link>
                            </div>
                        })}

                        </div>
                        <div className="kls-header-right">
                            <div className="vsep"></div>
                            <div className="kls-header-message kls-header-catalog">
                                <Link to={"/catalogue"}  >
                                    <img className='icon-svg' src="/icons/squid-game.svg" alt="catalog" ></img>
                                </Link>

                            </div>
                            {/*    <div className='save-app-icon'>
                                <i className='fa fa-save' onClick={()=>setSaveAll(true)}></i>
                              </div>*/}
                              
                            <div className="vsep"></div>
                            <div className={"dropdown kls-header-notification " + (showNotification && "show")}>
                                <button  className={"btn-link "+ (notificationMark ? "new" : "") } onClick={() => { setShowNotification(!showNotification); setShowProfile(false) }}  >
                                    <img className='icon-svg' src="/icons/bell.svg" alt="notification" ></img></button>
                                <NotificationPanel onClose={() => setShowNotification(false)}
                                    setCount={(d) => setNotificationMark(d > 0)} ></NotificationPanel>
                            </div>
                            
                            <div className="vsep"></div>
                           {currentUser() &&  <div ref={dropdownDisconnectRef} className={"dropdown kls-profile-menu " + (showProfile && "show")}>
                                <div className="kls-avatar">
                                    <button className="btn-link  kls-img-user" onClick={() => setShowProfile(!showProfile)} >
                                        <Avatar connectedUser size="small"></Avatar>
                                    </button>
                                    <div className='kls-avatar-tenant'>@{currentUser().entityLabel}</div>
                                </div>

                                <ProfileMenu onClose={() => setShowProfile(false)} postSignOut={props.postSignOut}></ProfileMenu>
                            </div>}
                        </div>
                    </div>
                </div>
                <div className="main-content-scrollable">
                    {props.children}
                </div>
                {saveAll && <SaveApplicationForm gs={gs} onClose={() => setSaveAll(false)}  ></SaveApplicationForm>}

            </div>
        </div>)
}




export default Layout;
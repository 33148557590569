 
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toYYYMMDDHHMM } from "kls-commons/help/dates";
import  t from "kls-i18n";
import {   GS } from "kls-commons/state/global-state";
import { transformeEnTitre } from "kls-commons/help/formating"; 
import {     Model } from "kls-commons/types/application-model";
import { DropdownList } from "kls-ui";
import "./module-editor.css";
import { NewModelWizard } from "./new-model-wizard";
import { SaveApplicationForm } from "./save-application-from";
import { getWorkingDirectorTree } from "kls-commons/service/repo-service";
import { WorkingDirTree } from "kls-commons/types/repo-model";
import { ModuleHistory } from "./module-history";

const STATUS_OPTIONS = [
  { label: t("En edition"), value: "editing" },
  { label: t("Commited"), value: "commited" },
  { label: t("Tout"), value: "" },
];
const ORIGIN_OPTIONS = [
  { label: t("Importé"), value: "imported" },
  { label: t("Crée"), value: "created" },
  { label: t("Tout"), value: "created" },
];

export function KLSModuleEditor(props) { 
  const handleChangeModuleName = ( moduleName:string ) => {
    props.handleTitlesChange( moduleName ) ;
  } 
  return (
    <div className={"module-editor"}>
      <LocalModelesSearch handleChangeModuleName={handleChangeModuleName} ></LocalModelesSearch>
    </div>
  );
}

async function  loadAllModule(callback:Function, moduleName: string) {
  const tree : WorkingDirTree  =  await getWorkingDirectorTree(moduleName || GS.currentModuleName);
  const models: Model[] = [];
  mapTreeToModels(tree,  models);
  callback(models);
}

function mapTreeToModels(tree: WorkingDirTree, models: Model[]) {
  if(tree.model) {
  //  const pattern = new RegExp(`klsapp/[a-z0-9]+/${GS.currentModuleName}/`, 'g');
  const pattern = new RegExp(`klsapp/[a-z0-9]+/`, 'g');
    
    let moduleName = tree.info!.filePath.replaceAll(pattern, '');
    moduleName = moduleName.replace('/'+tree.info!.fileName, '');
    moduleName = moduleName.replaceAll(/\//g, '::'); 
    let model: Model = {
      installionTime: toYYYMMDDHHMM(new Date(tree.info!.creationTime)),
      changeTime: tree.info!.changeTime ,
      label: tree.name, 
      modelName: tree.name,
      moduleName: moduleName, 
      description: "", 
    }; 
    models.push(model);
  }
  if(tree.children) {
      for(let k of Object.keys(tree.children)) {
        let c= tree.children[k];
        if(c.directory) {
          mapTreeToModels(c, models);
        }
      }
    }
}
export function LocalModelesSearch( {handleChangeModuleName} ) {
  let { serviceId } = useParams() as any;   
  let [models, setModels] = useState<Model[]>([]);
  let [searchKey, setSearchKey] = useState("");
  let [searchStatus, setSerchStatus] = useState();
  let [searchOrigin, setSerchOrigin] = useState();
  let [showSave, setShowSave] = useState(false);
  let [showHistory, setShowHistory] = useState(false);
  let [showCreationWizar, setShowCreationWizar] = useState(false);
  useEffect(() => { 
    serviceId = serviceId.toLowerCase().replaceAll(" " , "_" ) ;

    console.log("serviceIdParam ="+serviceId); 
    loadAllModule(setModels,serviceId);

  }, [searchKey, searchStatus, searchOrigin, serviceId]);

  useEffect(
    () => {
      handleChangeModuleName( serviceId ) ;
    } , [serviceId]
  )

  let onSubmitSave = (action: string) => {};
  return (
    <div className="model-browser">
      <div className="mb-actions">
        <button
          className="service-action-btn"
          onClick={() => setShowCreationWizar(true)}
        >
          <img src="/icons/create-new-file.svg" alt="" />
          <span>{t("Créer nouveau modèle")}</span>
        </button>

        <button className="service-action-btn neg" onClick={() => setShowHistory(true)} >
          <i className="fa fa-history" />
          <span>{t("Historique")}</span>
        </button>
 
          <button
            className="service-action-btn"
            onClick={() => setShowSave(true)}
          >
            <img src="/icons/share-from-square.svg" alt="" />
            <span>{t("Push to repo")}</span>
          </button>
        
      </div>
      <div className="mb-console">
        <input
          className="search-keys"
          placeholder={t(
            "Rechercher les modèles par mots clés (Nom, description, auteur, ...)"
          )}
          value={searchKey}
          onChange={(e) => setSearchKey(e.target.value)}
        ></input>
        <div className="search-filter">{t("Filres")}</div>
        <DropdownList
          className="view-screen-droplist"
          data={STATUS_OPTIONS}
          value={searchStatus}
          onSelect={setSerchStatus}
          placeholder={t("Statut")}
        ></DropdownList>
        <DropdownList
          className="view-screen-droplist"
          data={ORIGIN_OPTIONS}
          value={searchOrigin}
          onSelect={setSerchOrigin}
          placeholder={t("Origine")}
        ></DropdownList>
      </div>

      <div className="models-list">
        {models.map((m, i) => {
          return (
            <div key={m.modelName + "-" + i} className="model-row">
              <div className="model-cell mb-fx col-large ">
                <img src="/icons/open-folder.svg" alt="open folder"></img>
                <div className="mb-tf">
                  <div className="mb-title">{transformeEnTitre(m.label)}</div>
                  <div className="mb-qn">{m.modelName}</div>
                  {m.changeTime && (
                    <div className="mb-ct">
                      Modifié le {toYYYMMDDHHMM(m.changeTime)}
                    </div>
                  )}
                </div>
              </div>
              <div className="vsep"></div>
              <div className="model-cell col-small">
                <div className="mb-t">
                  <div className="mb-hdr">{t("statut")}</div>
                  <div className="mb-tag">
                    {m.editing ? t("En edition") : t("Commited")}
                  </div>
                </div>
              </div>

              <div className="vsep"></div>
              <div className="model-cell col-small">
                <div className="mb-t">
                  <div className="mb-hdr">{t("Origine")}</div>
                  <div className="mb-tag"> {t("Importé")}</div>
                </div>
              </div>

              <div className="vsep"></div>
              <div className="model-cell col-med ">
                <div className="mb-action">
                  <Link
                    to={
                      "/edit-service/" +
                     m.moduleName+
                      "/" +m.modelName
                    }
                    className="mb-btn"
                  >
                    {t("Editer")}
                  </Link>
                </div>
                <div className="vsep"></div>
                <div className="mb-action">
                  <div className="mb-btn">{t("Dupliquer")}</div>
                </div>
                <div className="vsep"></div>
                <div className="mb-action">
                  <div className="mb-btn">{t("Plus") + "..."}</div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {showSave && (
        <SaveApplicationForm
          moduleName={serviceId}
          onClose={() => setShowSave(false)}
          onOK={(action) => {
            onSubmitSave(action);
          }}
        ></SaveApplicationForm>
      )}

      {showCreationWizar && <NewModelWizard onClose={() => setShowCreationWizar(false)} onOK={() => {setShowCreationWizar(false)}} ></NewModelWizard>}

      {showHistory && <ModuleHistory moduleName={serviceId} onClose={() => setShowHistory(false)}   ></ModuleHistory>}
    </div>
  );
}
function filterModels(
  models: Model[],
  searchKey: string,
  searchStatus: undefined,
  searchOrigin: undefined
): import("react").SetStateAction<Model[]> {
  return models.filter(
    (m) => !searchKey || filterResources(m, toToken(searchKey))
  );
}

function filterResources(m: Model, searchKey: string): boolean {
  if (toToken(m.modelName).includes(searchKey)) {
    return true;
  }
  if (toToken(m.label).includes(searchKey)) {
    return true;
  }
  return false;
}

function toToken(s: string): string {
  if (!s) {
    return "";
  }
  return s.toLocaleLowerCase().replaceAll(/\s/g, "");
}

 


import { useEffect, useState } from "react"; 
import  t from "kls-i18n";
import { getFromStorage, GS, saveGlobaleState } from "kls-commons/state/global-state";
import { b64DecodeUnicode,   modelNameToURL  } from "kls-commons/help/tools";
import { 
  Model,
} from "kls-commons/types/application-model";
import { DropdownList } from "kls-ui";
import {Base64} from "js-base64" 
import "./kls-module.css"
import { useHistory } from "react-router-dom";
import { TemplateModel } from "kls-commons/types/repo-model";
import {cloneModel, fetchKlsResource, getWorkingDirectorTree, writeKlsResource } from "kls-commons/service/repo-service";

export function NewModelWizard(props) { 
   
  let history= useHistory();
    
  let [error, setError] = useState("");
  let [templateModels, setTemplateModels]= useState( [] as TemplateModel[]);
  let [mtc, setMtc] = useState({moduleName: GS.currentModuleName, proto: ''} as any);
  useEffect(()=> {
      getFromStorage('TEMPLATE_MODELS').then(m=> {
        setTemplateModels(m); 
    })
  }, []) ;
  let createModel = ()=> { 
      let moduleName = props.newModule ? mtc.moduleName: mtc.module.name;
      cloneModel(moduleName, mtc.name,  mtc.proto.modelQN,   mtc.proto.name );
     
      saveGlobaleState(GS); 
      props.onClose();
      history.push(    "/edit-service/" +  moduleName +   "/" +  mtc.name)      
   
  }
  let validForm=() => {
    setError("")
    if(props.newModel) {
      if(!mtc.moduleName || mtc.moduleName.length<3 ) {
        setError("Module name is required");
        return false;
      }
    }
    if(!mtc.name) {
      setError("Model name is required");
      return false;
    }
    if(!mtc.proto) {
      setError("Prototype is required");
      return false;
    }
 
    return true;
  } 
  let onAction = (name) => { 
    if (name === "close" || name === "cancel") {
    //  editings.forEach((e) => (e.saved = false));
      saveGlobaleState(GS); 
      props.onClose(); 
      return;
    }
    if(validForm()) { 
      createModel();
    }
  };

  
  let onChange=(path)=> {
    if(path==="name" || path==="packagePath") {
      mtc.qn=(mtc.packagePath || '' ).toLowerCase().trim()+"/"+(mtc.name || '').toLowerCase();
      
    }
    if(path==="qn" || path==="packagePath") {
      let lsi = (mtc.qn as string).lastIndexOf("/");
      mtc.packagePath=(mtc.qn.substring(0,lsi)|| '').toLowerCase();
      mtc.name=capitalize(mtc.qn.substring(lsi+1) || '');

      
    }
    setMtc({...mtc}); 
  }
  return (
    <div className="modale-popup save-app ">
      <div className="inner-popup-mid  large ">
        <i className="-times" onClick={props.onClose}></i>
        <div className="popup-header">
          {t("Nouveau "+(props.newModule? "Module": "Modèle")) }</div>
        <div className="popup-body">
          <div className="services-block">
            {error && <div className="error-message"> {error}  </div>}

            { props.newModule ? 
             <>
             <TextField model={mtc} path="moduleName" label={t("Module Name")} placeholder={t("Module name")}  /> 
             <TextField model={mtc} path="moduleTtile" label={t("Module Title")} placeholder={t("Module title")}  /> 
             <TextAreaField model={mtc} path="moduleDescription" label={t("Module Description")} placeholder={t("model description")} /> 
             <div className="popup-hsep"></div>
             </>   :
            <ListField search={false} model={mtc} path="module" data={GS.modules} label={t("Module")}  placeholder={t("choose a module")} /> 
            }
            <TextField model={mtc} path="title" label={t("Model Title")} placeholder={t("model title")}  /> 
            <TextField model={mtc} path="name" label={t("Model Name")} placeholder={t("model Name")}
             onChange={()=>onChange("name")}  /> 
            <TextField model={mtc} path="packagePath" label={t("Package Name")} placeholder={t("a/b/c")}
             onChange={()=>onChange("packagePath")}  /> 
            <TextField model={mtc} path="qn" label={t("Qualified Name")} placeholder={t("Qualified Name")} 
            onChange={()=>onChange("qn")} /> 
            <TextAreaField model={mtc} path="description" label={t("Description")} placeholder={t("model description")} /> 
            <ListField search={true} model={mtc} path="proto" data={templateModels} label={t("Prototype")}  placeholder={t("choose a module")} />           
          </div>
        </div>
        <div className="popup-footer">
          <button
            onClick={() => onAction("cancel")}
            className={"btn btn-tlb cancel-btn"}
          >
            <i className={"-times"}></i>
            <span>{t("Fermer")}</span>
          </button>

          <button
            onClick={() => onAction("ok")}
            className={"btn btn-tlb ok-btn"}
          >
            <i className={"-check"}></i>
            <span>{t("Valider")}</span>
          </button>
        </div>
      </div>
    </div>
  );
}

function ListField(props) { 
  
  let [value, setValue] = useState(props.model[props.path]); 
  return   <div className={"popup-field text-field field-"+props.path}>
  <span className="popup-field-label">{props.label}</span>
  <DropdownList
        className="view-screen-droplist"
        placeholder={props.placeholder}
        enableSearch={props.search}
        data={props.data}
        value={{ name: value }}
        onSelect={(d: any) => {
          props.model[props.path] = d; 
          setValue(d.name);
        }}
      />
</div>
}


function TextField(props) { 
  
  let [value, setValue] = useState(props.model[props.path]);
  useEffect(()=>{setValue(props.model[props.path])}, [props.model, props.path]);
  return   <div className={"popup-field text-field field-"+props.path}>
  <span className="popup-field-label">{props.label}</span>
  <input
    value={value  || ""}
    placeholder={props.placeholder || ""}
    onChange={(evt) => {

      props.model[props.path] = evt.target.value;
      setValue(evt.target.value);
      if(props.onChange) {
        props.onChange();
      }
    }}  
  ></input>
</div>
}


function TextAreaField(props) { 
  
  let [value, setValue] = useState(props.model[props.path]);
  return   <div className={"popup-field text-field field-"+props.path}>
  <span className="popup-field-label">{props.label}</span>
  <textarea
    value={value  || ""}
    placeholder={props.placeholder || ""}
    onChange={(evt) => {

      props.model[props.path] = evt.target.value;
      setValue(evt.target.value);
    }}
  ></textarea>
</div>
}


function createNewModel(mtc: any, proto:Model): Model | null {
   let m = {...proto};
   let name= mtc.name ; 
   let qn= mtc.qn; 
  
  m.label = mtc.title || mtc.name;
  m.description = mtc.description || m.label;
  m.modelName= mtc.qn
  m.editing= false;

  console.log("createNewModel from ", proto);
 
  return m;

}

function refactorGenericResources(rsc: any, proto:Model, name: string, qn:string)  {
  let sqn = proto.modelName;
  console.log("sqn=", sqn);
  let lsi = sqn.lastIndexOf("/");
  let sName =  sqn.substring(lsi+1) ||  sqn;
  console.log("sName=", sName);
  let sNameLC  = sName.toLocaleLowerCase();
  let nameLC  = name.toLocaleLowerCase();
  
  console.log("refactorGenericResources "+ name+" qn="+qn+" rsc.name="+ rsc.name, rsc);
  if(rsc.initialXmlData) {
      rsc.initialXmlData = replaceNameAndQN(rsc.initialXmlData , sqn, sName, sNameLC, qn, name, nameLC);
     }
  if(rsc.resourceQN) { 
       rsc.resourceQN= replaceNameAndQN(rsc.resourceQN.toLowerCase() , sqn, sName, sNameLC, qn, name, nameLC);
    }
  if(rsc.root) {
         rsc.root=replaceNameAndQN(rsc.root , sqn, sName, sNameLC, qn, name, nameLC);
  }
  rsc.fileName =  replaceNameAndQN(rsc.fileName , sqn, sName, sNameLC, qn, name, nameLC);
  rsc.name =  replaceNameAndQN(rsc.name , sqn, sName, sNameLC, qn, name, nameLC);
  if(rsc.contentB64) {
    let dcdd = b64DecodeUnicode(rsc.contentB64);
    dcdd = replaceNameAndQN(dcdd, sqn, sName, sNameLC, qn, name, nameLC);
    console.log("dcdd", dcdd);
    rsc.contentB64=Base64.encode(dcdd);
  }

}
function replaceNameAndQN(s: string, sqn: string, sName: string, sNameLC: string, qn:string, name: string, nameLC: string) :string{
  if(!s) return s;
  return s.replace(sqn, qn).replace(capitalize(sName), capitalize(name)).replace(sName, name).replace(sNameLC, nameLC);
}

 



function capitalize(s: string) {
 if(!s) return "";
 return (s.substring(0, 1).toUpperCase()+s.substring(1)).trim();
}



import { useEffect, useRef, useState } from 'react';
import { useParams } from "react-router-dom"; 
import ModelContentMenu from "../menu/app-aside-menu";
import {   GS, saveGlobaleState } from "kls-commons/state/global-state";  
import { MenuEditor } from "kls-generic-editor/menu-editor";
import { KConfigEditor } from "kls-generic-editor/kconfig-editor";
import { menuEditorSchemaRoot } from "kls-generic-editor/menu-editor-schema";
import { configsEditorSchemaRoot } from "kls-generic-editor/kconfig-editor-schema";
import { toCamelCase } from "kls-commons/service/resource-service";
import { OptionListEditor } from "kls-generic-editor/option-list-editor";
import { BpmnEditor } from "kls-bpmn-editor/process-editor/bpmn-editor"; 
import { FormViewEditor } from "kls-view-editor/view-editor";  
import { FormSearchEditor } from "kls-search-editor";
import {fetchKlsResource, getWorkingDirectorTree, writeKlsResource } from "kls-commons/service/repo-service";
import { ResourceContent, WorkingDirTree } from 'kls-commons/types/repo-model'; 
import 'kls-view-editor/view-edition.css';
import 'kls-view-editor/plugins/xmlViewer/xml-viewer.css';
import { blankTemplateBpmn, templateBpmn1, templateBpmn2, templateBpmn3, templateBpmn4, templateBpmn5 } from '../templates/templatesBpmn';
import { blankTemplate, template1 , template2, template3, template4, template5} from '../templates/viewTemplates';
import { tableTemplate } from '../templates/searchTemplates';

 

 
const emptyWDT : WorkingDirTree = {
    name: ''
}
const emptyResourceContent: ResourceContent = {
    content: ''
}
export function EServiceEditor(props) {
    let { serviceId,  modelName } = useParams() as any;   
    let [editing, setEditings] = useState(emptyWDT) ; 
    let [currentModel, setCurrentModel] = useState(emptyWDT);
    let [resourceContent, setResourceContent] = useState(emptyResourceContent);
    const [rubrique, setRubrique] = useState('');
    let [showSideMenu, setShowSideMenu] = useState(false);
    useEffect( ()=>{ 
        serviceId = serviceId.toLowerCase().replaceAll(" " , "_" ) ;
        modelName = modelName.toLowerCase().replaceAll(" " , "_" ) ;
        let moduleName = serviceId.replaceAll("::", "/", modelName);
        
        console.log( "debuging :: : " , props.handleGsChange , serviceId,  modelName, moduleName )
        
        getWorkingDirectorTree(moduleName).then(wdt=> {
            setEditings(wdt);
            if(wdt.children && modelName) { 
                setCurrentModel(wdt.children[modelName]);
                setRubrique(getRubricFromHistory(  wdt.children[modelName] ) ||   '');

                console.log( "wdt.children[modelName] )", wdt.children[modelName] ) ;
                GS.currentModuleName=moduleName;
                GS.currentModelName=modelName;
                props.handleTitlesChange( GS.currentModuleName, GS.currentModelName ) ;
                saveGlobaleState(GS);
            }
        }) 
    },[serviceId, modelName])  ; 


    
    const startEdit=(sr:string | undefined)  => { 
        if(!sr) return;
        fetchKlsResource(GS.currentModuleName, sr)
        .then(c=> { 
            setRubrique(sr);
            addRubricToHistory(currentModel, sr);
            setResourceContent(c);
            console.log(">>>"+ sr);
        });  

    } 

    useEffect( ()=>{ 
         if(rubrique) {
            startEdit(rubrique);
         }
    },[rubrique])  ; 

    const informSave = useRef<HTMLDivElement>( null ) ;

    const onSaveResource=(rc:ResourceContent) => {
        if(!rc || !rc.content || !rc.info) {
            return;
        }
        console.log("onSaveResource", rc);
        writeKlsResource(GS.currentModuleName, rc.info.filePath, rc.content);
        if(rc.extraContent) {
            Object.keys(rc.extraContent).forEach(k=> {
                let ec = rc.extraContent![k];
                let path = rc.info!.filePath.replace( rc.info!.fileName, k);
                writeKlsResource(GS.currentModuleName, path, ec).then(
                    () => {
                        if( informSave && informSave.current ) {
                            informSave.current.style.display = "flex" ;
                            setTimeout(
                                () => {
                                    if( informSave && informSave.current )
                                        informSave.current.style.display = "none" ;
                                } , 2000
                            )
                        }
                    }
                )
            })
        }
    }

    const createNewBpmn = ( name : string , resourceQN : string , template : string ) : any => {
        const valueRootMap = {
            blank:blankTemplateBpmn,
            template1:templateBpmn1,
            template2:templateBpmn2,
            template3:templateBpmn3,
            template4:templateBpmn4,
            template5:templateBpmn5,
        };
        const bpmn = valueRootMap[template](name) ;
        return writeKlsResource(GS.currentModuleName, resourceQN, bpmn).then(
            r => { 
                startEdit(resourceQN);
                return r ;
            }
        );
    }
    const createNewSearch = (name: string, resourceQN: string, template: string) => {
        const valueRootMap = {
            blank: tableTemplate,
            template1: tableTemplate,
            template2: tableTemplate,
            template3: tableTemplate,
            template4: tableTemplate,
            template5: tableTemplate,
        };
        const search = valueRootMap[template](name);
        return writeKlsResource(GS.currentModuleName, resourceQN, search).then(
            r => {
                startEdit(resourceQN);
                return r;
            }
        );
    }
 
    const createNewView = ( name : string, resourceQN : string , template : string ) => {
        const valueRootMap = {
            blank:blankTemplate,
            template1:template1,
            template2:template2,
            template3:template3,
            template4:template4,
            template5:template5,
        };
        const view = valueRootMap[template](name) ;
        return writeKlsResource(GS.currentModuleName, resourceQN, view).then(
            r => { 
                startEdit(resourceQN);
                return r ;
            }
        );
    }

    const getTenant = () : string => {
        const user = window["currentUser"] ;
        return !user.entity.match("/kls/[a-z0-9]+/dev") ? null : user.entity.replace("/kls/", "").replace("/dev", "") ;
    }


    const createNewElement = async ( rubrique:string , name:string, fileName:string, template:string ) => {
        if( rubrique == 'workflows' ) {
            console.log( "debuging currentModel : "  , currentModel)
            let processToAdd = await createNewBpmn( name , "klsapp/" + getTenant() + "/" + GS.currentModuleName + "/" + currentModel.name + "/process/" + fileName + ".bpmn"  , template ) ;
            console.log( "processToAdd", processToAdd ) 
        } else if( rubrique == 'views' ) {
            let viewToAdd = await createNewView( name , "klsapp/" + getTenant() + "/" + GS.currentModuleName + "/" + currentModel.name + "/" + "view-" + fileName + ".xml" , template) ;
            console.log( "viewToAdd", viewToAdd )
        }else if (rubrique == 'searchs') {
            let searchToAdd = await createNewSearch( name , "klsapp/" + getTenant() + "/" + GS.currentModuleName + "/" + currentModel.name + "/search/" + fileName + ".xml", template);
            console.log("searchToAdd", searchToAdd)
        }

        getWorkingDirectorTree(GS.currentModuleName).then(wdt=> {
            setEditings(wdt);
            if(wdt.children && modelName) { 
                setCurrentModel(wdt.children[modelName]);
                setRubrique(getRubricFromHistory( wdt.children[modelName] ) || '' );
            }
        })

    } 

    return <div className={"service-editor " + (showSideMenu ? "menu-show" : "")}>
 
            <div className="leftside-menu">
                <ModelContentMenu  
                    model = {currentModel}
                    kpiMenuLastUpdate={props.kpiMenuLastUpdate} 
                    setShowSideMenu={setShowSideMenu} 
                    startEdit={startEdit}
                    createNewElement={createNewElement}
                ></ModelContentMenu>
            </div>
            <div className="file-editor">

                {rubrique.endsWith("-menu.xml")  &&  <MenuEditor    onSave={onSaveResource} 
                 rubrique={rubrique} resourceContent={resourceContent} schema={menuEditorSchemaRoot} ></MenuEditor>} 

                 {rubrique.endsWith("config.xml")  &&  <KConfigEditor    onSave={onSaveResource} 
                  rubrique={rubrique} resourceContent={resourceContent} schema={configsEditorSchemaRoot} ></KConfigEditor>} 

                {rubrique.endsWith("option-list") &&  <OptionListEditor gs={props.gs} editing={editing} 
                 rubrique={rubrique} model={currentModel} schema={menuEditorSchemaRoot} ></OptionListEditor>} 

                {(rubrique.endsWith(".xml") && rubrique.includes("/view-")) &&  <FormViewEditor onSave={onSaveResource}    resourceContent={resourceContent} ></FormViewEditor>}   

                {/*rubrique.type==="component" &&  <FormComponentEditor gs={props.gs} editing={editing} 
                 submitPanelRenderer={(a, r)=><SaveApplicationForm  onClose={r} onOK={a}  />}
                    rubrique={rubrique} model={currentModel} ></FormComponentEditor>*/}
              
                {rubrique.includes("/taskList") &&  <FormViewEditor  onSave={onSaveResource}    resourceContent={resourceContent} ></FormViewEditor>}
                {rubrique.includes("/search/") &&  <FormSearchEditor  onSave={onSaveResource}    resourceContent={resourceContent} ></FormSearchEditor>}
                 
                {rubrique.endsWith(".bpmn") &&  <BpmnEditor  onSave={onSaveResource}    resourceContent={resourceContent}        ></BpmnEditor>}

            </div> 
            <div ref={informSave} className='inform-save' style={{display:"none"}}> 
                <span className='icon fa fa-check'></span>
                <span>Changements effectués avec succès!</span>
            </div>
    </div>  

}

function getRubricFromHistory( cwdt: WorkingDirTree):  string | undefined  {
    if(!cwdt || !cwdt.info)
        return;
    let rj = localStorage.getItem("currentRubrics") ;
    if(!rj)
        return ;
    let rubrics = JSON.parse(rj);
    if(!rubrics)
        return;
    return rubrics[cwdt.info?.filePath]
    
}
function addRubricToHistory(cwdt: WorkingDirTree, sr: string ) {
    if(!cwdt || !cwdt.info)
        return;
    let rj = localStorage.getItem("currentRubrics") ;
    if(!rj)
        rj = '{}' ;
    let rubrics = JSON.parse(rj); 
    rubrics[cwdt.info?.filePath] = sr;
    localStorage.setItem("currentRubrics", JSON.stringify(rubrics));
    localStorage.setItem("currentModelName", cwdt.name );
    GS.currentModelName= cwdt.name;
    saveGlobaleState(GS);
}


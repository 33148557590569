

export const blankTemplateBpmn = (name:string) =>  `<?xml version="1.0" encoding="UTF-8"?>
                                            <bpmn2:definitions xmlns:bpmn2="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:tns="http://org.eclipse.bpmn2.modeler.runtime.karaz.kbpm3" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" xmlns:di="http://www.omg.org/spec/DD/20100524/DI" xmlns:xs="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" id="Definitions_1" exporter="org.eclipse.bpmn2.modeler.core" exporterVersion="1.3.0.Final-v20160602-2145-B47" targetNamespace="http://sample.bpmn2.org/bpmn2/sample/process">
                                                <bpmn2:process id="DEFAULT_BPMN" name="${name}" isExecutable="true"/>
                                                <bpmndi:BPMNDiagram id="BPMNDiagram_1" name="Default Process Diagram">
                                                    <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="name"/>
                                                </bpmndi:BPMNDiagram>
                                            </bpmn2:definitions>`;


export const templateBpmn1 = (name:string) =>  `<?xml version="1.0" encoding="UTF-8"?>
                                            <bpmn2:definitions xmlns:bpmn2="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:tns="http://org.eclipse.bpmn2.modeler.runtime.karaz.kbpm3" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" xmlns:di="http://www.omg.org/spec/DD/20100524/DI" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xs="http://www.w3.org/2001/XMLSchema" id="Definitions_1" targetNamespace="http://sample.bpmn2.org/bpmn2/sample/process" exporter="org.eclipse.bpmn2.modeler.core" exporterVersion="1.3.0.Final-v20160602-2145-B47">
                                            <bpmn2:process id="DEFAULT_BPMN" name="${name}" isExecutable="true">
                                                <bpmn2:startEvent id="Event_19a85ca">
                                                <bpmn2:outgoing>Flow_1gf46yr</bpmn2:outgoing>
                                                </bpmn2:startEvent>
                                                <bpmn2:endEvent id="Event_0ee6v4w">
                                                <bpmn2:incoming>Flow_1gf46yr</bpmn2:incoming>
                                                </bpmn2:endEvent>
                                                <bpmn2:sequenceFlow id="Flow_1gf46yr" sourceRef="Event_19a85ca" targetRef="Event_0ee6v4w" />
                                            </bpmn2:process>
                                            <bpmndi:BPMNDiagram id="BPMNDiagram_1" name="Default Process Diagram">
                                                <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="DEFAULT_BPMN">
                                                <bpmndi:BPMNShape id="Event_19a85ca_di" bpmnElement="Event_19a85ca">
                                                    <dc:Bounds x="252" y="122" width="36" height="36" />
                                                </bpmndi:BPMNShape>
                                                <bpmndi:BPMNShape id="Event_0ee6v4w_di" bpmnElement="Event_0ee6v4w">
                                                    <dc:Bounds x="582" y="122" width="36" height="36" />
                                                </bpmndi:BPMNShape>
                                                <bpmndi:BPMNEdge id="Flow_1gf46yr_di" bpmnElement="Flow_1gf46yr">
                                                    <di:waypoint x="288" y="140" />
                                                    <di:waypoint x="582" y="140" />
                                                </bpmndi:BPMNEdge>
                                                </bpmndi:BPMNPlane>
                                            </bpmndi:BPMNDiagram>
                                            </bpmn2:definitions>`

export const templateBpmn2 = (name:string) =>  `<?xml version="1.0" encoding="UTF-8"?>
                                            <bpmn2:definitions xmlns:bpmn2="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:tns="http://org.eclipse.bpmn2.modeler.runtime.karaz.kbpm3" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" xmlns:di="http://www.omg.org/spec/DD/20100524/DI" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xs="http://www.w3.org/2001/XMLSchema" id="Definitions_1" targetNamespace="http://sample.bpmn2.org/bpmn2/sample/process" exporter="org.eclipse.bpmn2.modeler.core" exporterVersion="1.3.0.Final-v20160602-2145-B47">
                                            <bpmn2:process id="DEFAULT_BPMN" name="${name}" isExecutable="true">
                                                <bpmn2:startEvent id="Event_19a85ca">
                                                <bpmn2:outgoing>Flow_1gf46yr</bpmn2:outgoing>
                                                </bpmn2:startEvent>
                                                <bpmn2:endEvent id="Event_0ee6v4w">
                                                <bpmn2:incoming>Flow_0kj3ix8</bpmn2:incoming>
                                                </bpmn2:endEvent>
                                                <bpmn2:sequenceFlow id="Flow_1gf46yr" sourceRef="Event_19a85ca" targetRef="Activity_1pde3zf" />
                                                <bpmn2:userTask id="Activity_1pde3zf" name="User task">
                                                <bpmn2:incoming>Flow_1gf46yr</bpmn2:incoming>
                                                <bpmn2:outgoing>Flow_0kj3ix8</bpmn2:outgoing>
                                                </bpmn2:userTask>
                                                <bpmn2:sequenceFlow id="Flow_0kj3ix8" sourceRef="Activity_1pde3zf" targetRef="Event_0ee6v4w" />
                                            </bpmn2:process>
                                            <bpmndi:BPMNDiagram id="BPMNDiagram_1" name="Default Process Diagram">
                                                <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="DEFAULT_BPMN">
                                                <bpmndi:BPMNShape id="Event_19a85ca_di" bpmnElement="Event_19a85ca">
                                                    <dc:Bounds x="252" y="122" width="36" height="36" />
                                                </bpmndi:BPMNShape>
                                                <bpmndi:BPMNShape id="Event_0ee6v4w_di" bpmnElement="Event_0ee6v4w">
                                                    <dc:Bounds x="582" y="122" width="36" height="36" />
                                                </bpmndi:BPMNShape>
                                                <bpmndi:BPMNShape id="Activity_085ewnt_di" bpmnElement="Activity_1pde3zf">
                                                    <dc:Bounds x="400" y="100" width="100" height="80" />
                                                </bpmndi:BPMNShape>
                                                <bpmndi:BPMNEdge id="Flow_1gf46yr_di" bpmnElement="Flow_1gf46yr">
                                                    <di:waypoint x="288" y="140" />
                                                    <di:waypoint x="400" y="140" />
                                                </bpmndi:BPMNEdge>
                                                <bpmndi:BPMNEdge id="Flow_0kj3ix8_di" bpmnElement="Flow_0kj3ix8">
                                                    <di:waypoint x="500" y="140" />
                                                    <di:waypoint x="582" y="140" />
                                                </bpmndi:BPMNEdge>
                                                </bpmndi:BPMNPlane>
                                            </bpmndi:BPMNDiagram>
                                            </bpmn2:definitions>`

export const templateBpmn3 = (name:string) =>  `<?xml version="1.0" encoding="UTF-8"?>
                                            <bpmn2:definitions xmlns:bpmn2="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:tns="http://org.eclipse.bpmn2.modeler.runtime.karaz.kbpm3" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" xmlns:di="http://www.omg.org/spec/DD/20100524/DI" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xs="http://www.w3.org/2001/XMLSchema" id="Definitions_1" targetNamespace="http://sample.bpmn2.org/bpmn2/sample/process" exporter="org.eclipse.bpmn2.modeler.core" exporterVersion="1.3.0.Final-v20160602-2145-B47">
                                            <bpmn2:process id="DEFAULT_BPMN" name="${name}" isExecutable="true">
                                                <bpmn2:startEvent id="Event_19a85ca">
                                                <bpmn2:outgoing>Flow_1gf46yr</bpmn2:outgoing>
                                                </bpmn2:startEvent>
                                                <bpmn2:endEvent id="Event_0ee6v4w">
                                                <bpmn2:incoming>Flow_0kj3ix8</bpmn2:incoming>
                                                </bpmn2:endEvent>
                                                <bpmn2:sequenceFlow id="Flow_1gf46yr" sourceRef="Event_19a85ca" targetRef="Activity_1pde3zf" />
                                                <bpmn2:sequenceFlow id="Flow_0kj3ix8" sourceRef="Activity_1pde3zf" targetRef="Event_0ee6v4w" />
                                                <bpmn2:scriptTask id="Activity_1pde3zf" name="Script task">
                                                <bpmn2:incoming>Flow_1gf46yr</bpmn2:incoming>
                                                <bpmn2:outgoing>Flow_0kj3ix8</bpmn2:outgoing>
                                                </bpmn2:scriptTask>
                                            </bpmn2:process>
                                            <bpmndi:BPMNDiagram id="BPMNDiagram_1" name="Default Process Diagram">
                                                <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="DEFAULT_BPMN">
                                                <bpmndi:BPMNShape id="Event_19a85ca_di" bpmnElement="Event_19a85ca">
                                                    <dc:Bounds x="252" y="122" width="36" height="36" />
                                                </bpmndi:BPMNShape>
                                                <bpmndi:BPMNShape id="Event_0ee6v4w_di" bpmnElement="Event_0ee6v4w">
                                                    <dc:Bounds x="582" y="122" width="36" height="36" />
                                                </bpmndi:BPMNShape>
                                                <bpmndi:BPMNShape id="Activity_0jbkbid_di" bpmnElement="Activity_1pde3zf">
                                                    <dc:Bounds x="400" y="100" width="100" height="80" />
                                                    <bpmndi:BPMNLabel />
                                                </bpmndi:BPMNShape>
                                                <bpmndi:BPMNEdge id="Flow_1gf46yr_di" bpmnElement="Flow_1gf46yr">
                                                    <di:waypoint x="288" y="140" />
                                                    <di:waypoint x="400" y="140" />
                                                </bpmndi:BPMNEdge>
                                                <bpmndi:BPMNEdge id="Flow_0kj3ix8_di" bpmnElement="Flow_0kj3ix8">
                                                    <di:waypoint x="500" y="140" />
                                                    <di:waypoint x="582" y="140" />
                                                </bpmndi:BPMNEdge>
                                                </bpmndi:BPMNPlane>
                                            </bpmndi:BPMNDiagram>
                                            </bpmn2:definitions>`

export const templateBpmn4 = (name:string) =>  `<?xml version="1.0" encoding="UTF-8"?>
                                            <bpmn2:definitions xmlns:bpmn2="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:tns="http://org.eclipse.bpmn2.modeler.runtime.karaz.kbpm3" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" xmlns:di="http://www.omg.org/spec/DD/20100524/DI" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xs="http://www.w3.org/2001/XMLSchema" id="Definitions_1" targetNamespace="http://sample.bpmn2.org/bpmn2/sample/process" exporter="org.eclipse.bpmn2.modeler.core" exporterVersion="1.3.0.Final-v20160602-2145-B47">
                                            <bpmn2:process id="DEFAULT_BPMN" name="${name}" isExecutable="true">
                                                <bpmn2:startEvent id="Event_19a85ca">
                                                <bpmn2:outgoing>Flow_1fpipzt</bpmn2:outgoing>
                                                </bpmn2:startEvent>
                                                <bpmn2:endEvent id="Event_0ee6v4w">
                                                <bpmn2:incoming>Flow_1ubsqs5</bpmn2:incoming>
                                                </bpmn2:endEvent>
                                                <bpmn2:userTask id="Activity_1okw4fp" name="User task 1">
                                                <bpmn2:incoming>Flow_1fpipzt</bpmn2:incoming>
                                                <bpmn2:outgoing>Flow_1pobj4g</bpmn2:outgoing>
                                                </bpmn2:userTask>
                                                <bpmn2:userTask id="Activity_1padwjw" name="User task 2">
                                                <bpmn2:incoming>Flow_1pobj4g</bpmn2:incoming>
                                                <bpmn2:outgoing>Flow_1ubsqs5</bpmn2:outgoing>
                                                </bpmn2:userTask>
                                                <bpmn2:sequenceFlow id="Flow_1fpipzt" sourceRef="Event_19a85ca" targetRef="Activity_1okw4fp" />
                                                <bpmn2:sequenceFlow id="Flow_1pobj4g" sourceRef="Activity_1okw4fp" targetRef="Activity_1padwjw" />
                                                <bpmn2:sequenceFlow id="Flow_1ubsqs5" sourceRef="Activity_1padwjw" targetRef="Event_0ee6v4w" />
                                            </bpmn2:process>
                                            <bpmndi:BPMNDiagram id="BPMNDiagram_1" name="Default Process Diagram">
                                                <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="DEFAULT_BPMN">
                                                <bpmndi:BPMNShape id="Event_19a85ca_di" bpmnElement="Event_19a85ca">
                                                    <dc:Bounds x="252" y="122" width="36" height="36" />
                                                </bpmndi:BPMNShape>
                                                <bpmndi:BPMNShape id="Activity_1bxs2cl_di" bpmnElement="Activity_1okw4fp">
                                                    <dc:Bounds x="390" y="100" width="100" height="80" />
                                                    <bpmndi:BPMNLabel />
                                                </bpmndi:BPMNShape>
                                                <bpmndi:BPMNShape id="Event_0ee6v4w_di" bpmnElement="Event_0ee6v4w">
                                                    <dc:Bounds x="752" y="122" width="36" height="36" />
                                                </bpmndi:BPMNShape>
                                                <bpmndi:BPMNShape id="Activity_1c1r2nb_di" bpmnElement="Activity_1padwjw">
                                                    <dc:Bounds x="580" y="100" width="100" height="80" />
                                                </bpmndi:BPMNShape>
                                                <bpmndi:BPMNEdge id="Flow_1fpipzt_di" bpmnElement="Flow_1fpipzt">
                                                    <di:waypoint x="288" y="140" />
                                                    <di:waypoint x="390" y="140" />
                                                </bpmndi:BPMNEdge>
                                                <bpmndi:BPMNEdge id="Flow_1pobj4g_di" bpmnElement="Flow_1pobj4g">
                                                    <di:waypoint x="490" y="140" />
                                                    <di:waypoint x="580" y="140" />
                                                </bpmndi:BPMNEdge>
                                                <bpmndi:BPMNEdge id="Flow_1ubsqs5_di" bpmnElement="Flow_1ubsqs5">
                                                    <di:waypoint x="680" y="140" />
                                                    <di:waypoint x="752" y="140" />
                                                </bpmndi:BPMNEdge>
                                                </bpmndi:BPMNPlane>
                                            </bpmndi:BPMNDiagram>
                                            </bpmn2:definitions>`

export const templateBpmn5 = (name:string) =>   `<?xml version="1.0" encoding="UTF-8"?>
                                            <bpmn2:definitions xmlns:bpmn2="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:tns="http://org.eclipse.bpmn2.modeler.runtime.karaz.kbpm3" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" xmlns:di="http://www.omg.org/spec/DD/20100524/DI" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xs="http://www.w3.org/2001/XMLSchema" id="Definitions_1" targetNamespace="http://sample.bpmn2.org/bpmn2/sample/process" exporter="org.eclipse.bpmn2.modeler.core" exporterVersion="1.3.0.Final-v20160602-2145-B47">
                                            <bpmn2:process id="DEFAULT_BPMN" name="${name}" isExecutable="true">
                                                <bpmn2:startEvent id="Event_19a85ca">
                                                <bpmn2:outgoing>Flow_1fpipzt</bpmn2:outgoing>
                                                </bpmn2:startEvent>
                                                <bpmn2:endEvent id="Event_0ee6v4w">
                                                <bpmn2:incoming>Flow_16hzbu2</bpmn2:incoming>
                                                </bpmn2:endEvent>
                                                <bpmn2:userTask id="Activity_1okw4fp" name="User task">
                                                <bpmn2:incoming>Flow_1fpipzt</bpmn2:incoming>
                                                <bpmn2:incoming>Flow_0zz4q3o</bpmn2:incoming>
                                                <bpmn2:outgoing>Flow_1pobj4g</bpmn2:outgoing>
                                                </bpmn2:userTask>
                                                <bpmn2:sequenceFlow id="Flow_1fpipzt" sourceRef="Event_19a85ca" targetRef="Activity_1okw4fp" />
                                                <bpmn2:sequenceFlow id="Flow_1pobj4g" sourceRef="Activity_1okw4fp" targetRef="Gateway_133lsgm" />
                                                <bpmn2:exclusiveGateway id="Gateway_133lsgm">
                                                <bpmn2:incoming>Flow_1pobj4g</bpmn2:incoming>
                                                <bpmn2:outgoing>Flow_16hzbu2</bpmn2:outgoing>
                                                <bpmn2:outgoing>Flow_1d9wx8m</bpmn2:outgoing>
                                                </bpmn2:exclusiveGateway>
                                                <bpmn2:sequenceFlow id="Flow_16hzbu2" sourceRef="Gateway_133lsgm" targetRef="Event_0ee6v4w" />
                                                <bpmn2:scriptTask id="Activity_0zhig5n" name="Script task">
                                                <bpmn2:incoming>Flow_1d9wx8m</bpmn2:incoming>
                                                <bpmn2:outgoing>Flow_0zz4q3o</bpmn2:outgoing>
                                                </bpmn2:scriptTask>
                                                <bpmn2:sequenceFlow id="Flow_0zz4q3o" sourceRef="Activity_0zhig5n" targetRef="Activity_1okw4fp" />
                                                <bpmn2:sequenceFlow id="Flow_1d9wx8m" sourceRef="Gateway_133lsgm" targetRef="Activity_0zhig5n" />
                                            </bpmn2:process>
                                            <bpmndi:BPMNDiagram id="BPMNDiagram_1" name="Default Process Diagram">
                                                <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="DEFAULT_BPMN">
                                                <bpmndi:BPMNShape id="Event_19a85ca_di" bpmnElement="Event_19a85ca">
                                                    <dc:Bounds x="252" y="122" width="36" height="36" />
                                                </bpmndi:BPMNShape>
                                                <bpmndi:BPMNShape id="Activity_1bxs2cl_di" bpmnElement="Activity_1okw4fp">
                                                    <dc:Bounds x="390" y="100" width="100" height="80" />
                                                    <bpmndi:BPMNLabel />
                                                </bpmndi:BPMNShape>
                                                <bpmndi:BPMNShape id="Event_0ee6v4w_di" bpmnElement="Event_0ee6v4w">
                                                    <dc:Bounds x="752" y="122" width="36" height="36" />
                                                </bpmndi:BPMNShape>
                                                <bpmndi:BPMNShape id="Gateway_133lsgm_di" bpmnElement="Gateway_133lsgm" isMarkerVisible="true">
                                                    <dc:Bounds x="595" y="115" width="50" height="50" />
                                                </bpmndi:BPMNShape>
                                                <bpmndi:BPMNShape id="Activity_05wfvbz_di" bpmnElement="Activity_0zhig5n">
                                                    <dc:Bounds x="570" y="240" width="100" height="80" />
                                                    <bpmndi:BPMNLabel />
                                                </bpmndi:BPMNShape>
                                                <bpmndi:BPMNEdge id="Flow_1fpipzt_di" bpmnElement="Flow_1fpipzt">
                                                    <di:waypoint x="288" y="140" />
                                                    <di:waypoint x="390" y="140" />
                                                </bpmndi:BPMNEdge>
                                                <bpmndi:BPMNEdge id="Flow_1pobj4g_di" bpmnElement="Flow_1pobj4g">
                                                    <di:waypoint x="490" y="140" />
                                                    <di:waypoint x="595" y="140" />
                                                </bpmndi:BPMNEdge>
                                                <bpmndi:BPMNEdge id="Flow_16hzbu2_di" bpmnElement="Flow_16hzbu2">
                                                    <di:waypoint x="645" y="140" />
                                                    <di:waypoint x="752" y="140" />
                                                </bpmndi:BPMNEdge>
                                                <bpmndi:BPMNEdge id="Flow_0zz4q3o_di" bpmnElement="Flow_0zz4q3o">
                                                    <di:waypoint x="570" y="280" />
                                                    <di:waypoint x="440" y="280" />
                                                    <di:waypoint x="440" y="180" />
                                                </bpmndi:BPMNEdge>
                                                <bpmndi:BPMNEdge id="Flow_1d9wx8m_di" bpmnElement="Flow_1d9wx8m">
                                                    <di:waypoint x="620" y="165" />
                                                    <di:waypoint x="620" y="240" />
                                                </bpmndi:BPMNEdge>
                                                </bpmndi:BPMNPlane>
                                            </bpmndi:BPMNDiagram>
                                            </bpmn2:definitions>`

export const blankTemplate = (elementName:string)=>
    `<view name="${elementName.replaceAll(" ",'').toLowerCase()}" label="${elementName}" className="empty-view">
        <screen label="${elementName}" className="version2 " usage="update:R">
        <vbox className="form-root"></vbox>
        </screen>
        <buttonBar><button  label="Retourner" type="submit" xpath="to" value="previous" />
        <button label="Valider" type="submit" xpath="to" value="next"/></buttonBar>
        <statusBar indicator="new.status.bar" type="historique">
      <status label="Premier activite" state="firstActivitie" />
    </statusBar>
    </view>`;

    export const template1 =(elementName:string)=>`<view name="${elementName.replaceAll(" ",'').toLowerCase()}" label="${elementName}" className="empty-view">
<screen label="${elementName}" className="version2" usage="update:R">
<vbox className="form-root">        
<vbox className="partition-style" style="height: 40%">
<vbox/>
</vbox>
<vbox className="partition-style" style="height: 60%">
<vbox/>
</vbox>
</vbox>
</screen>
<buttonBar>
<button usage="sp:N,vp:R" label="Retourner" type="submit" xpath="to" value="previous" />
<button label="Valider" type="submit" xpath="to" value="next" />
</buttonBar>
<statusBar indicator="new.status.bar" type="historique">
      <status label="Premier activite" state="firstActivitie" />
    </statusBar>
</view>`;

export const template2=(elementName)=>
`<view name="${elementName.replaceAll(" ",'').toLowerCase()}" label="${elementName}" className="empty-view">
                            <screen label="${elementName}" className="version2" usage="update:R">
                            <vbox style="background-color: white;height: 30%;margin:10px;border-radius:5px">
                            <vbox/>
                            </vbox>
                            <vbox columns="20% 80%" className="partition-gbox" style="height: 70%">
                            <vbox className="partition-style" style="height: 100%"/>
                            <vbox className="partition-style" style="height:100%"/>
                            </vbox>
                        </screen>
                        <buttonBar>
                          <button label="Retourner" type="submit" xpath="to" value="previous" />
                          <button label="Valider" type="submit" xpath="to" value="next" />
                          </buttonBar>
                          <statusBar indicator="new.status.bar" type="historique">
                          <status label="Premier activite" state="firstActivitie" />
                        </statusBar>
                      </view>`;

 export const template3=(elementName) =>` <view name="${elementName.replaceAll(" ",'').toLowerCase()}" label="${elementName}" className="empty-view">
<screen label="${elementName}" className="version2" usage="update:R">
<vbox style="background-color: white;height: 30%;margin:10px;border-radius:5px">
                            <vbox/>
                            </vbox>
                            <vbox columns="80% 20%" className="partition-gbox" style="height: 70%">
                            <vbox className="partition-style" style="height: 100%"/>
                            <vbox className="partition-style" style="height:100%"/>
                            </vbox>
 </screen>
<buttonBar>
<button label="Valider" type="submit" xpath="to" value="next" />
<button label="Retourner" type="submit" xpath="to" value="previous" usage="cr:H,val:R" />
<button label="Rejeter" type="submit" xpath="to" value="rejet" usage="cr:H,val:R" />
</buttonBar>
<statusBar indicator="new.status.bar" type="historique">
      <status label="Premier activite" state="firstActivitie" />
    </statusBar>
</view>`

export const template4 =(elementName)=> `<view name="${elementName.replaceAll(" ",'').toLowerCase()}" label="${elementName}" className="empty-view">
<screen label="${elementName}" className="version2" usage="update:R">
<vbox style="background-color: white;height: 30%;margin:10px;border-radius:5px">
                            <vbox/>
                            </vbox>
                            <vbox columns="20% 60% 20%" className="partition-gbox" style="height: 70%">
                            <vbox className="partition-style" style="height: 100%"/>
                            <vbox className="partition-style" style="height: 100%"/>
                            <vbox className="partition-style" style="height:100%"/>
                            </vbox>
</screen><buttonBar><button usage="sp:N,vp:R" label="Retourner" type="submit" xpath="to" value="previous" />
<button label="Valider" type="submit" xpath="to" value="next" /></buttonBar>
<statusBar indicator="new.status.bar" type="historique">
<status label="Premier activite" state="firstActivitie" />
</statusBar>
</view>`;

export const template5 =(elementName)=> `<view name="${elementName.replaceAll(" ",'').toLowerCase()}" label="${elementName}" className="empty-view">
<screen label="${elementName}" className="version2" usage="update:R">
<vbox columns="20% 80%" className="partition-gbox" style="height: 100%">
<vbox className="partition-style" style="height: 100%"/>
<vbox>
<vbox className="partition-style" style="height: 30% "/>
<vbox className="partition-style" style="height: 70% "/>
</vbox>

</vbox>
</screen>
<buttonBar>
<button usage="sp:N,vp:R" label="Retourner" type="submit" xpath="to" value="previous" />
<button label="Valider" type="submit" xpath="to" value="next" />
</buttonBar>
<statusBar indicator="new.status.bar" type="historique">
      <status label="Premier activite" state="firstActivitie" />
    </statusBar>
</view>`;


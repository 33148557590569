import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom"; 
import  t from "kls-i18n"; 
import { Popup } from "kls-ui";
import { WorkingDirTree } from "kls-commons/types/repo-model";
import { getNameOfResource, toCamelCase } from "kls-commons/service/resource-service";

 
export function ModelContentMenu(props) { 
    
    let location = useLocation();
    let [state, setStateXX] = useState({ showAside: false, displayedBlock: "dashboard" } as any) ;
    const [showCreateNewElementPopup , setShowCreateNewElementPopup] = useState( false ) ;

    const [selectedRubrique , setSelectedRubrique] = useState( "" ) ;

    const [mapResourceNameLabel, setMapResourceNameLabel] = useState<any>( {} ) ;

    let [rubriques, setRubriques] = useState([] as any[]);
    
    async function generationRubrique( generatedRubriques ) {
        for (const rubrique of generatedRubriques) {
            if (rubrique.name === "workflows" || rubrique.name === "views" || rubrique.name === "searchs") {
                mapResourceNameLabel[rubrique.name] = await Promise.all(
                    rubrique.children.map(async (r) => {
                        let label = await getNameOfResource(r.resourceQN);
                        return {
                            fileName : r.name,
                            label : label
                        };
                    })
                );
                mapResourceNameLabel[rubrique.name] = mapResourceNameLabel[rubrique.name].reduce((acc, item) => {
                    acc[item.fileName] = item.label;
                    return acc;
                }, {});
            }
        }
        return mapResourceNameLabel ;
    }

    useEffect(() => {  
        if (!props.model) { 
            setRubriques([]);
            return;
        } 
        let generatedRubriques = generateRubriquesFromModel(props.model); 
        setRubriques(generatedRubriques);

        generationRubrique( generatedRubriques ).then(
            mapResourceNameLabel => {
                setMapResourceNameLabel( {...mapResourceNameLabel} ) ;
            }
        ) ;
    }, [props.model]);



    let setState = (s) => {
        setStateXX(s);
        props.setShowSideMenu(s.showAside); 
    }

    let showMenuDetails = (r) => {
        setState({ showAside: true, displayedBlock: r.name });
    }

    let collapseAside = () => {
        setState({ showAside: false });
    }
    let getLinkCss = (r: any) => {
        return "nav-link " + (state.displayedBlock === r.name ? "active" : "");
    }

    let getBlockCSS = (blockName: string): string => {
        return "kls-iconbar-pane " + (state.displayedBlock === blockName ? "show" : "");
    }

    const handlerClickCreateNewElement = (rubrique: string) => {
        setShowCreateNewElementPopup(true);
        setSelectedRubrique(rubrique);
    }
    const startEditNewElement = (rubrique: string, newElementName: string) => {
        const srubrique = generateRubriquesFromModel(props.model).find(r => r.name == rubrique);
        const newElement = srubrique.children.find((sr: any) => sr.name == newElementName);
        collapseAside()
        props.startEdit(newElement);
    }

    useEffect(() => {
        if (window["innerWidth"] < 760) {
            props.setShowSideMenu(false);
        }
    }, [location.pathname]);


    return (<>
        <div className={"kls-iconbar "}>
            <nav className="nav"  >
                {rubriques.map((r, indx) => {
                    return <button key={"m-" + r.name} className={"btn-link " + getLinkCss(r)} onClick={() => showMenuDetails(r)}>
                        <IconHolder icon={r.icon} />
                        <span className="title" >{r.label}</span>
                    </button>
                })}
            </nav>
        </div>

        <div className="kls-under-aside" style={{ display: (state.showAside ? 'unset' : 'none') }} onClick={collapseAside}></div>

        <div className={"kls-iconbar-aside " + (state.showAside && "show")}>

            <div className="kls-iconbar-body">
                {rubriques.map((r, ii) => {
                    return <div key={"rub-" + ii} id={"rub-" + r.name} className={getBlockCSS(r.name)} >
                        <h6 className="kls-iconbar-title">   <IconHolder icon={r.icon} /> <span> {r.label}</span>
                            <i onClick={collapseAside} className="icon fa fa-times"></i>
                        </h6>

                        <ul className="nav">
                            {
                                r.children?.map((sr, jj) => {
                                    return <li key={"sr-" + jj} className="nav-item  RRRR ">
                                        <div onClick={() => { console.log("debuging rubrique resourceQN : ", sr); props.startEdit(sr.resourceQN, r); collapseAside() }} className="nav-link">
                                            <span> <i className={sr.icon}></i> {sr.label}  </span>
                                        </div>
                                        {sr.children && <ul className="nav-item-sitem">
                                            {
                                                sr.children.map((ssr, kk) => {
                                                    return <li key={ssr.label + "-" + kk}>
                                                        <div onClick={() => { props.startEdit(ssr.resourceQN, r); collapseAside() }} className="nav-link">
                                                        <span> <i className={ssr.icon}></i> {ssr.label.replace(".xml","")}  </span>
                                                        </div>
                                                    </li>
                                                })}
                                        </ul>
                                        }
                                    </li>
                                })
                            }

                            <CreateNewElement rubrique={r.name} handlerClickCreateNewElement={() => handlerClickCreateNewElement(r.name)} />

                        </ul>

                    </div>
                })}
            </div>
        </div>

        {
            showCreateNewElementPopup &&
            <CreateNewElementPopup 
                mapResourceNameLabel={mapResourceNameLabel}
                rubrique={selectedRubrique}
                onClose={() => { setShowCreateNewElementPopup(false) }}
                model={props.model}
                onAction={(name: string, fileName: string, selectedValue: string) => {
                    if (name.trim() !== "") {
                        props.createNewElement(selectedRubrique, name, fileName, selectedValue);

                        startEditNewElement(selectedRubrique, fileName);

                        setShowCreateNewElementPopup(false);
                    }
                }}
            />
        }

    </>);

}



function CreateNewElementPopup({ mapResourceNameLabel, rubrique, onClose, onAction, model }: { mapResourceNameLabel, rubrique: string, onClose: Function, onAction: Function, model:WorkingDirTree  }) {
    const [selectedValue, setSelectedValue] = useState('blank');
    const [selectedSearchTypeOption, setSelectedSearchTypeOption] = useState('search');

    const templatesView = [
        { id: 'blank', name: 'Sans partition', image: 'blankView.png' },
        { id: 'template1', name: 'Partition 1', image: 'temp1View.png' },
        { id: 'template2', name: 'Partition 2', image: 'temp2View.png' },
        { id: 'template3', name: 'Partition 3', image: 'temp3View.png' },
        { id: 'template4', name: 'Partition 4', image: 'temp4View.png' },
        { id: 'template5', name: 'Partition 5', image: 'temp5View.png' },
    ];

    
    const templatesSearch = [
        { id: 'blank', name: 'Tableau', image: 'search-template-table.svg' },
        { id: 'template1', name: 'Liste', image: 'search-template-list.svg' },
        { id: 'template2', name: 'Liste multi-colonnes', image: 'search-template-listmc.svg' },
        { id: 'template3', name: 'Calandrier', image: 'search-template-calendar.svg' },
        { id: 'template4', name: 'Carte', image: 'search-template-map.svg' },
        { id: 'template5', name: 'Slide', image: 'search-template-slide.svg' },


    ];

    const templatesBpmn = [
        { id: 'blank', name: 'Vide', image: 'blankBpmn.png' },
        { id: 'template1', name: 'BPMN 1', image: 'temp1Bpmn.png' },
        { id: 'template2', name: 'BPMN 2', image: 'temp2Bpmn.png' },
        { id: 'template3', name: 'BPMN 3', image: 'temp3Bpmn.png' },
        { id: 'template4', name: 'BPMN 4', image: 'temp4Bpmn.png' },
        { id: 'template5', name: 'BPMN 5', image: 'temp5Bpmn.png' },
    ];

    const [selectedTemplate, setSelectedTemplate] = useState("blank");

    let resourceName:string = "" ;
    
    if( ["workflows", "views"].includes(rubrique) ) {
        let resources:string[] = [] ;
        if( rubrique === "workflows"  ) {
            resources = Object.keys(model?.children?.process?.children||{}).filter(r => r.endsWith(".bpmn")).map( p => p.replace(".bpmn",""))
            resourceName = "Nouveau processus "
        } else if( rubrique === "views" ) {
            resources = Object.keys(model?.children||{}).filter(r => r.startsWith("view-") && r.endsWith(".xml")).map( p => p.replace(".xml","").replace("view-","")) 
            resourceName = "Nouvelle vue "
        }
        let i = 1 ;
        while( resources.includes( resourceName + i ) ) {
            i++ ;
        }
        resourceName = resourceName + i ;
    }
    
    function generateFileName(phrase) {
        const words = phrase.trim().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[^a-zA-Z0-9_-]/g, ' ').split(/\s+/);
        console.log("words : ", words);
        const camelCaseWords = words.map((word, index) => index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1));
        const camelCaseString = camelCaseWords.join('');
        return camelCaseString;
    }
    const [name, setName] = useState( resourceName );
    let [fileName, setFileName] = useState( "" );

    
    useEffect(
        () => {
            console.log( "debuging fileName:", fileName )
            fileName = generateFileName( name ) ;

            setFileName( fileName ) ;
        } , [name]
    )

    let [error, setError] = useState<string|undefined>() ;

    let validCreationResource = ( name ) => {
        if( !name || !name.trim() ) {
            if( rubrique === "workflows"  ) {
                return "Veuillez saisir le nom";
            } else if( rubrique === "views" ) {
                return "Veuillez saisir le nom";
            }
        }

        // let resources = Object.keys(mapResourceNameLabel[rubrique]).map(k => mapResourceNameLabel[rubrique][k])
        // if( resources.some( r => r.toUpperCase() === name.trim().toUpperCase() ) ) {
        //     if( rubrique === "workflows"  ) {
        //         return "Veuillez changer le nom";
        //     } else if( rubrique === "views" ) {
        //         return "Veuillez changer le nom";
        //     }
        // }
    }    


    const onValidate = (action: string) => {
        if (action == "ok") {
            error = validCreationResource( name ) ;
            setError( error ) ;
            if( !error )
                onAction(name, fileName, selectedValue);
            else return ;
        }
        onClose()
    };

    const handleChangeValue = (value) => {
        const prevSelectedElement = document.getElementById(selectedTemplate!);
        if (prevSelectedElement) {
            prevSelectedElement.classList.remove('selected-image');
        }
        setSelectedTemplate(value);
        const currentSelectedElement = document.getElementById(value);
        if (currentSelectedElement) {
            currentSelectedElement.classList.add('selected-image');
        }
        setSelectedValue(value);

    };
    
    const handleSearchTypeOptionChange = (e) => {
        setSelectedSearchTypeOption(e.target.value);
    };

    const handleChangeNameResource = (e) => {
        setName( e.target.value ) ;
    }

    return <Popup onClose={onClose}
        onAction={onValidate}
        large="large"
        actions={[{ name: 'cancel', label: t('Annuler'), icon: 'times' }, { name: 'ok', label: t('Créer'), icon: 'plus' }]}>

        <div className="popup-fields-container">
            {error && <div className="error-message"> {error}  </div>}

            {
                rubrique == "views" && <>
                    <div className="form-container form-new-element">
                        <div className="field-container">
                            <label htmlFor="view-name">Nom écran</label>
                            <input type="text" id="view-name" value={name} onChange={ handleChangeNameResource } />
                        </div>
                    </div>
                
                    <div className="form-container form-new-element">
                        <div className="field-container">
                            <label>Select a template</label>
                            <div className="template-options">
                                {templatesView.map((template) => (
                                    <TemplateOption
                                        key={template.id}
                                        template={template}
                                        selectedTemplate={selectedTemplate}
                                        onSelect={handleChangeValue}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </>
            }
   {
                rubrique == "searchs" && <>
                    <div className="form-container form-new-element">
                        <div className="field-container">
                            <div style={{   display: "grid",
                                            gridTemplateColumns: "50% 50%",
                                            justifyContent: "center",
                                            gap: "1.2em"}}>
                                <div style={{
                                        justifyContent: "right"

                                }}>
                                    <label style={{    display: "flex"}} >
                                        <input
                                            type="radio"
                                            value="search"
                                            style={{ width: "min-content",
                                            boxShadow: "none", 
                                            margin: "3px"
                                        }}
                                            checked={selectedSearchTypeOption === 'search'}
                                            onChange={handleSearchTypeOptionChange}
                                        />
                                        Recherche
                                    </label>
                                </div>

                                <div style={{
                                        justifyContent: "left"
                                }}>
                                    <label style={{    display: "flex"}} >
                                        <input
                                        
                                        style={{ width: "min-content",
                                            boxShadow: "none", 
                                            margin: "3px"
                                        }}
                                            type="radio"
                                            value="notification"
                                            checked={selectedSearchTypeOption === 'notification'}
                                            onChange={handleSearchTypeOptionChange}
                                        />
                                        Liste de tâches
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="field-container">
                            <label htmlFor="view-name">Nom recherche <span style={{ color: "red" }} >*</span>  </label>
                            <input type="text" id="view-name" value={name} onChange={(e) => setName(e.target.value)} />
                        </div>
                    </div>

                    <div className="form-container form-new-element">
                        <div className="field-container">
                            <label>Select a template</label>
                            <div className="template-options">
                                {templatesSearch.map((template) => (
                                    <TemplateOption
                                        key={template.id}
                                        template={template}
                                        selectedTemplate={selectedTemplate}
                                        onSelect={handleChangeValue}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </>
            }
            {
                rubrique == "workflows" && <>
                    <div className="form-container form-new-element">
                        <div className="field-container">
                            <label htmlFor="view-name">Process Name</label>
                            <input type="text" id="view-name" value={name} onChange={(e) => setName(e.target.value)} />
                        </div>
                    </div>

                    <div className="form-container form-new-element">
                        <div className="field-container">
                            <label>Select a template</label>
                            <div className="template-options">
                                {templatesBpmn.map((template) => (
                                    <TemplateOption
                                        key={template.id}
                                        template={template}
                                        selectedTemplate={selectedTemplate}
                                        onSelect={handleChangeValue}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </>
            }

        </div>

    </Popup>
}

function CreateNewElement({ rubrique, handlerClickCreateNewElement }: { rubrique: string, handlerClickCreateNewElement: any }) {

    return (rubrique === "workflows" || rubrique === "views" || rubrique=== 'searchs') ? <button onClick={handlerClickCreateNewElement} className="create-new-element" title={rubrique === "workflows" ? "Ajouter un nouveau processus" : rubrique === "views" ? "Ajouter une nouvelle vue" : ""} >
        <i className="fa fa-plus"></i>
    </button> : <></>
}
function IconHolder({ icon }) {
    return icon && icon.endsWith(".svg") ? <img src={"/icons/" + icon} alt={icon}></img> : <i className={icon}></i>
}
export default ModelContentMenu;

 
function generateRubriquesFromModel(model: WorkingDirTree): any[] {
    console.log("generateRubriquesFromModel", model);
    let modelName = model.info?.filePath;
    model.children =model.children || {} 
    let files = Object.keys(model.children).map(k=>model.children![k]);
    files = files.flatMap(x => {
        if(x.name==="process" || x.name==="search") {
            return Object.keys(x.children!).map(k=>x.children![k])|| [];
        }
        return [x];
    })
    return  [

        {
            name: "views", label: t("Ecrans"), icon: "desktop.svg",
            children: files.filter(x=>x.name.startsWith("view-") && x.name.endsWith(".xml")).map(v => { 
                return { type: "view", 
                    name: v.name, label: t(v.name.replace("view-","").replace(".xml","")), 
                    icon: "fa fa-window-maximize", 
                    resourceQN:  v.info?.filePath,
                    link: "/edit/edit-view/" + v.name , 
                };
                }),
            modelName: modelName
        }

        ,
        {
            name: "workflows", label: t("Processus"), icon: "gear.svg", 
            modelName:  modelName,
            children: files.filter(x=>x.name.endsWith(".bpmn")).map(p => { return { 
                
                resourceQN:  p.info?.filePath, type: "bpmn", name: p.name, label: t(  p.name.replace(".bpmn","")), icon: "fa fa-cog", link: "/edit/edit-process/" + p.name }; })
        },


        {
            name: "searchs", label: t("searchs"), icon: "manifiying-glass.svg",
            modelName: modelName,
            children: [{
                label: t('searchs'), icon: 'fa fa-cubes', 
                children: files.filter(x=>x.info?.filePath.includes("/search/"))?.map(p => {
                    return {
                        type: "search", name: p.name, label: t(p.name),
                        icon: "manifiying-glass.svg", link: "/edit/edit-search/" + p.name, 
                         resourceQN: p.info?.filePath
                    };
                })
            },
            {
                label: "Task Lists", icon: "fa fa-list",
                children: files.filter(x=>x.name.includes("taskList"))?.map(p => { return {  
                    resourceQN:  p.info?.filePath,
                    type: "search", name: p.name, label:   t(p.name), 
                    icon: "manifiying-glass.svg"}; })
            }
            ]
        },
        {
            name: "reports", label: t("reports"), icon: "file-lines.svg", 
            modelName:  modelName,
            children: files.filter(x=>x.name.endsWith("-report.xml"))?.map(p => { return { 
                resourceQN:  p.info?.filePath,
                type: "report", name: p.name, label: t(  p.name), icon: "fa fa-bars", link: "/edit/edit-report/" + p.name }; })
        },
        {
            name: "configuration", label: t("configurations"), icon: "screwdriver-wrench.svg",
            modelName: modelName,
            children: [{
                label: t('Menus'), icon: 'fa fa-bars', 
                children: files.filter(x=>x.name.endsWith("-menu.xml"))?.map(p => {
                    return {
                        resourceQN:  p.info?.filePath,
                        type: "menu", name: p.name, label: t( p.name),
                        icon: "fa fa-bars", link: "/edit/edit-menu/" + p.name
                    };
                })
            },
            {
                label: "Option Lists", icon: "fa fa-list-ol",
                modelName: modelName,
                children:files.filter(x=>x.name.endsWith("-dynamiclists.xml"))?.map(p => { 
                    return { type: "option-list", name: p.name, label: t(p.name),  resourceQN:  p.info?.filePath, icon: "manifiying-glass.svg", link: "/edit/edit-option-list/" + p.name }; })
            },

            {
                label: "KConfig", icon: "fa fa-cog", type: "kconfig", link: "/edit/edit-config",
                modelName:  modelName,
                resourceQN:  model.info?.filePath+"/kconfig.xml",
    
            }
            ]
        },


    ];
}

function TemplateOption({ template, selectedTemplate, onSelect }) {
    const imagePath = `/photos/${template.image}`;

    return (
        <div className="card-template">
            <img
                src={imagePath}
                className={`image-template ${selectedTemplate === template.id ? 'selected-image' : ''}`}
                alt={template.name}
                title={template.name}
                id={template.id}
                onClick={() => onSelect(template.id)}
            />
            <p>{template.name}</p>
        </div>
    );
}

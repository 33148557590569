import { useEffect, useState } from "react";
import "./App.css";
import Layout from "./layout/layout";
import { 
  BrowserRouter,
  HashRouter,
  Route,
  Router,
  Switch,
} from "react-router-dom";
import { fetchLocalUserProfile } from "kls-commons/service/userService"; 
import { PwdReset, Signup, Login } from "kls-auth"; 
import { CatalogEGov } from "kls-catalog/egov-services"; 
import { GS, setToStorage } from "kls-commons/state/global-state";
import { EServiceEditor } from "./editors/e-service-editor";
import { KLSModuleEditor } from "./kls-module/module-editor";
import { LayoutPublic } from "./layout/layout-public"; 
import { CatalogEGovPublic } from "kls-catalog/egov-services-public"; 
import { getTemplatesDirectoryTree } from "kls-commons/service/repo-service";
import { ServiceDetails } from "kls-catalog/egov-service-details";
import t from "kls-i18n";
import { COMPONENT_CATALOG } from "kls-view-editor/services/component-catalog-service";
import { NewModelWizard } from "./kls-module/new-model-wizard";
 
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/mode-groovy';

function AppHome(props: any) {   

  const [moduleTitle , setModuleTitle] = useState<string> ( GS.currentModuleName || GS.modules?.[0]?.name || '' ) ;
  const [modelTitle , setModelTitle] = useState<string> ( localStorage.getItem("currentModelName") || GS.currentModelName || '' ) ;


  const handleTitlesChange = (moduleName:string, modelName?:string) => {
    setModuleTitle( moduleName ) ;
    setModelTitle( modelName || "" ) ;
  }


  return (
      <Layout
        gs={props.gs}  
        postSignOut={()=>{}}
        modelTitle={modelTitle}
        moduleTitle={moduleTitle}
      >
        <Switch>
          <Route path="/edit-service/:serviceId/:modelName"> 
            <EServiceEditor gs={props.gs} handleTitlesChange={handleTitlesChange}></EServiceEditor>
          </Route>

          <Route path="/edit-service/:serviceId">
            <EServiceEditor gs={props.gs} handleTitlesChange={handleTitlesChange}></EServiceEditor>
          </Route>

          <Route path="/edit-module/:serviceId/:action">
            <KLSModuleEditor gs={props.gs} handleTitlesChange={handleTitlesChange}></KLSModuleEditor>
          </Route>
              
          <Route path="/kls/catalog/details/:serviceId">
            <ServiceDetails   gs={props.gs} ></ServiceDetails>
          </Route>

          <Route path="/catalogue">
            <CatalogEGov gs={props.gs} creationWizard={creationWizard(props.gs, props.editings)}  ></CatalogEGov>
          </Route>

          <Route path="/">
            <AppHomeUser handleTitlesChange={handleTitlesChange}></AppHomeUser>  
          </Route>
        </Switch>
      </Layout>
  );
} 

function creationWizard (   gs:any, editing: any)  { 
return (onOk, onClose)=> <NewModelWizard 
gs={gs}   editing={editing}
newModule={true}  onClose={onClose}     onOK={onOk }   ></NewModelWizard>
}
export function AppHomPublic(props) {
  return (
    <LayoutPublic postSignIn={props.postSignIn}>
      <Switch>
      

        
        <Route path="/kls/catalog">
          <CatalogEGovPublic></CatalogEGovPublic>
        </Route>

        <Route path="/">
          <div className="cms-page">
            <div className="cms-page-title">
              {t(
                "Les services e-Gov que Citoyens et fonctionnaires méritent !"
              )} 
            </div>
            <p className="cms-page-parag">
              Rejoignez le #1 des plateformes No Code / Low Code dédiées au
              domaine e-Gov et accédez à un large catalogue de e-services prêts
              à l’emploi
            </p>
            <div className="cms-page-calltoaction">
              <img src="/icons/video-neg.svg" alt="video"></img>
              <span>{t("Voire vidéo intro")}</span>
            </div>
          </div>
        </Route>
      </Switch>
    </LayoutPublic>
  );
}
function App() {
  let [loading, setLoading] = useState(true);
  let [count, setCount] = useState(0);
  let [userProfile, setUserProfile] = useState(fetchLocalUserProfile());

  useEffect(() => {
    console.log("useEffect count "+count);
    if (!GS.changeTime && !COMPONENT_CATALOG.loaded) {
      setTimeout(() => setCount(count + 1), 500);
    } else {
      setLoading(false);
    } 
  }, [count]);

  useEffect(() => {
    getTemplatesDirectoryTree(undefined, undefined).then((s) => {
      if (s  ) { 
        setToStorage("TEMPLATE_MODELS", s);
      }
    });
  }, []);

  return loading ? (
    <div>Loading...</div>
  ) : (
    <HashRouter>
      <div className="App">
        <Switch>
          <Route path="/reset-pwd/:activationId/:email">
            <PwdReset></PwdReset>
          </Route>
          <Route path="/login">
            <Login postSignIn={setUserProfile}></Login>
          </Route>
          <Route path="/signup">
            <Signup postSignIn={setUserProfile}></Signup>
          </Route>
          <Route path="/">
            {userProfile && <AppHome gs={GS}></AppHome>}
            {
              !userProfile && /*( <AppHomPublic postSignIn={setUserProfile}></AppHomPublic> )*/
              <Login postSignIn={setUserProfile} ></Login>
            }
          </Route>
        </Switch>
      </div>
    </HashRouter>
  );
}

function AppHomeUser( {handleTitlesChange} ) {
  handleTitlesChange( "" , "" )
  return<></>
}

export default App;
